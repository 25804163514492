// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/
.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            bottom: 60px;
            left: 15%;
            right: 5%;
            display: flex;

            h3 {
                color: $color-white;
                font-size: $font-size-60;
                font-weight: 600;
                line-height: 1;
                text-transform: inherit;
            }

            h4, p {
              color: $color-white;
              font-weight: 600;
            }

            h4 {
                font-size: $font-size-18;
                margin-bottom: 15px;
            }

            .btn-wrapper {
                max-width: 260px;
                a.btn {
                  border: 1px solid $color-primary;
                  &:hover {
                      transition: all 0.2s ease-in;
                      background-color: rgba(255,255,255,0);
                      color: $color-white;
                  }
               }
            }
            div.main {
              flex: 0 0 auto;
              margin-right: auto;
            }
            div.textes {
                margin-right: 2rem;
                width: 275px;
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        &__wrapper {
            &_caption {
                div.textes {display: none;}
                h3 {
                    font-size: 2rem;
                }
            }
        }
    }
}

section.section-01-mobile {
    background-color: $color-secondary;
    padding: 20px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text-wrapper {
        padding: 0 15px;
    }
    h4, p {
      color:$color-white;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    p {
      font-size: 16px;
    }
    img {
        border-top-left-radius: 80px;
        border-top-right-radius: 80px;
    }
    @media screen and (min-width: $size-xs-max){display: none;}
}


section.section-01-accueil {
    margin-top: 40px;
    padding: 100px 0;
    background: linear-gradient(to right, $color-primary 0% ,$color-primary 80%, #fff 80%, #fff 100%);
    // border-top-left-radius: 80px 80px;
    // border-bottom-left-radius: 50%;

    div.left-side {
        width: 45%;
        padding-left: 5%;
        padding-right: 2rem;
    }
    div.right-side {
        width: 55%;
        img {
          border-top-left-radius: 60px;
          border-bottom-left-radius: 60px;
        }
    }

    h3 {
        font-size: 4vw;
        color: $color-black;
        text-transform: inherit;
    }
    h4 {
        color: $color-black;
        font-size: $font-size-26;
        font-weight: 600;
    }
    p {
        font-size: $font-size-20;
        color: $color-black;
    }

    .btn-wrapper {
        max-width: 270px;
    }
    .btn-black {
        background-color: $color-black;
        border: 1px solid $color-black;
        &:hover {
            background-color: rgba(255,255,255,0);
            font-weight: 500;
        }
    }
    @media screen and (max-width: $size-xs-max){
        margin-top: 0;
        padding: 60px 0;
        background: linear-gradient(to right, $color-primary 0% ,$color-primary 80%, $color-primary 80%, $color-primary 100%);
        //background-color: $color-primary;
        div.left-side {
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }
        div.right-side {
            display: none;
        }
        h3 {
            font-size: 30px
        }
        h4 {
            font-size: 22px;
        }
        p {
            font-size: 18px;
            font-weight: 500;
        }
    }
}


section.section-etapes {
    padding: 50px 10%;

    h3, p {
        text-align: center;
        font-weight: 700;
        color: $color-black;
    }

    h3 {
        font-size: $font-size-30;
    }
    p {
        font-size: 18px;
        position: relative;
        margin-bottom: 0;
        &::before {
            position: absolute;
            content: '1';
            font-size: 30px;
            color: #04050445;
            left: -30px;
            top: -8px;
        }
        &.etape2::before{content: '2'}
        &.etape3::before{content: '3'}
        &.etape4::before{content: '4'}
        &.etape5::before{content: '5'}
    }

    @media screen and (max-width: $size-md-max){
        .icons-wrapper {
            justify-content: center;
        }
    }
}

section.section-reviews {
    padding: 100px 0;
}

/*******************************************************************************
* PAGE X
*/

.pageX {
    section.header {

    }
}
div.container-fluid.row {
    margin: 0;
}
section.section-01-histoire {
    background-color: #F5F5F5;
    padding: 60px 0;
    div.container-fluid.row {
        box-shadow: -3px -3px 6px #FFFFFF,
                    3px 3px 6px #d4d0d0;
    }
    div.text-bloc {
        padding: 70px 10%;
    }
    h3 {
      color: #363636;
      text-align: center;
      font-size: $font-size-30;
    }
    p {
        font-size: $font-size-18;
        color: #040504;
        max-width: 1500px;
    }
    .btn-wrapper {
        max-width: 500px;
        margin: auto;
        .btn-black {
            margin-top: 50px;
            background: $color-black;
            color: $color-white;
            text-transform: uppercase;
            position: relative;
            div.first {
              transition: .3s;
              opacity: 1;
            }
            div.overlay {
              top: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              position: absolute;
              opacity: 0;

              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:hover {
                color: $color-black;
                background-color: rgba(0,0,0,0);
                border: 1px solid $color-black;
                div.first {
                    opacity: 0;
                    // transition: .3s;
                }
                div.overlay {
                    opacity: 1;
                }
            }
        }
    }
}

section.section-01-services {
    padding: 60px 10%;
    div.text-bloc {
        padding: 70px 0;
    }
    h3 {
      color: #363636;
      text-align: center;
      font-size: $font-size-30;
    }
    p {
        font-size: $font-size-18;
        color: #040504;
    }
    .btn-wrapper {
        max-width: 300px;
        //margin: auto;
        .btn-black {
            margin-top: 50px;
            background: $color-black;
            color: $color-white;
            text-transform: uppercase;
            &:hover {
                color: $color-black;
                background-color: rgba(0,0,0,0);
                border: 1px solid $color-black;
            }
        }
    }
    div.services-bloc {
        display: inline-grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap:10px;

        &>div:nth-child(1) {
            background-color: #324769;
        }
        &>div:nth-child(2) {
            background-color: #000;
        }
        &>div:nth-child(3) {
            background-color: #C06B2D;
        }
        &>div:nth-child(4) {
            background-color: #E9592D;
        }

        & > div {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          padding: 30px;
          height: 40vw;
          height: auto;
            h4, p {
                color: $color-white;
                text-align: left;
                line-height: 1.5;
            }
            h4 {
                font-size: $font-size-24;
            }
           p {
             font-size: $font-size-18;
           }
        }

    }
    @media screen and (max-width: 1600px){
        div.services-bloc > div {
          p {
            font-size: $font-size-16;
          }
        }
    }
    @media screen and (max-width: 1300px){
        padding: 60px 15px;
        div.services-bloc > div {
          height: auto;
        }
    }
    @media screen and (max-width: $size-sm-max){
      div.services-bloc {
          display: inline-grid;
          grid-template-rows: 1fr 1fr 1fr 1fr;
          grid-template-columns: 1fr;
          grid-gap:10px;
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.services-bloc {
            grid-template-rows: auto;
            & > div {
                padding: 30px 10px;
                p {
                  font-size: 16px;
                }
            }
        }
    }
}


section.section-01-processus {
    @extend .section-01-services;
    padding: 60px 10% 0 10%;
    div.text-bloc {
        padding: 0;
    }

    .btn-wrapper {
        margin: auto;
        .btn {
            background-color: rgba(255,255,255,0);
            border: 2px solid $color-black;
            color: $color-black;
            &:hover {
                background-color: $color-black;
                color: $color-white;
            }
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 30px 5% 0 5%;
    }
}

section.section-01-realisations {
    @extend .section-01-services;
    background-color: #F5F5F5;
    div.text-bloc {
        padding: 0;
    }
}

section.section-02-realisations {
  background-color: #F5F5F5;
  div.gallery-wrapper {
    .item {
        margin-bottom: 50px;
        &:last-child(){
          margin-bottom: 0;
        }
        position: relative;
        div.text {
            position: absolute;
            right: 0;
            left: 71%;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 30px;

            .subtitle {
                color: $color-black;
                font-size: $font-size-18;
                text-align: center;
                display: block;
                &.marbre {
                  color: $color-white;
                }
            }
            .title {
                @extend .font-1-bold;
                padding-bottom:0.5rem;
                color: $color-black;
                font-size: 2vw;
                text-align: center;
                display: block;
                &.marbre {
                  color: $color-white;
                }
            }

            .btn-slider {
                max-width: 250px;
                min-width: 200px;
                width: 16vw;
                margin: auto;
                margin-top: 25px;
                display: block;
                background-color: rgba(255,255,255,0);
                &.marbre {
                  border: 1px solid #eeb412;
                  color: $color-white;
                }
                &.evaluateur, &.garage {
                    background-color: rgba(255,255,255,0);
                    border: 1px solid $color-black;
                    color: $color-black;
                }

                &:hover {
                    background-color: $color-black;
                    border: 1px solid $color-black;
                    color: $color-white;
                }
            }
        }
     }
  }
  //style pour la gallerie en mobile
  div.gallery-wrapper-mobile {
    .item {
        margin-bottom: 50px;
        position: relative;
        div.text {
            position: absolute;
            right: 0;
            left: 0;
            top: 50%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;

            .subtitle {
                color: $color-black;
                font-size: 16px;
                text-align: center;
                display: block;
                &.marbre {
                  color: $color-white;
                }
            }
            .title {
                @extend .font-1-bold;
                padding-bottom:0.5rem;
                color: $color-black;
                font-size: 24px;
                text-align: center;
                display: block;
                &.marbre {
                  color: $color-white;
                }
            }

            .btn-slider {
                max-width: 250px;
                min-width: 200px;
                width: 16vw;
                margin: auto;
                margin-top: 25px;
                display: block;
                background-color: rgba(255,255,255,0);
                &.marbre {
                  border: 1px solid #eeb412;
                  color: $color-white;
                }
                &.evaluateur, &.garage {
                    background-color: rgba(255,255,255,0);
                    border: 1px solid $color-black;
                    color: $color-black;
                }

                &:hover {
                    background-color: $color-black;
                    border: 1px solid $color-black;
                    color: $color-white;
                }
            }
        }
     }
  }

}

section.realisation-text {
    background-color: #F5F5F5;
    padding-bottom: 50px;
    div.container-fluid.row {
        padding: 60px 10%;
    }

    h3 {
        color: #040504;
        text-align: left;
        font-size: $font-size-30;
    }

    div.icons-wrapper {
        box-shadow: -3px -3px 6px #FFFFFF,
                      3px 3px 6px #d4d0d0;
    }
    @media screen and (max-width: $size-md-max){
      div.container-fluid.row {
        flex-wrap: wrap;
      }
    }
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
          flex-direction: column;
        }
    }
}


section.section-coordonnees {
    background-color: #F5F5F5;
    padding-bottom: 50px;
    div.container-fluid.row {
        padding: 0 10%;
        display: flex;
        flex-wrap: wrap;
    }

    div.left-side {
        width: 45%;
    }
    div.right-side {
        width: 55%;
    }

    p {
      font-size: 16px;
    }
    h3 {
        font-size: $font-size-30;
        color: $color-black;
        padding-bottom: 20px;
        &::after {
            width: 60px;
            height: 3px;
            margin-top: 30px;
            content: '';
            display: block;
            background-color: $color-black;
        }
    }
    div.info {
        margin-bottom: 50px;
        .label {
            width: 170px;
            .icon {
                width: 40px;
            }
            h4 {
              font-size: 16px;
              font-weight: 700;
              color: $color-black;
            }
        }
        .value {
            a, p {
                color: #040504;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: $size-md-max){
        div.left-side, div.right-side {
            width: 100%;
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
            padding: 60px 5%;
            .form-group {
                padding: 0;
            }
        }
    }
    @media screen and (max-width: 350px) {
        div.info {
            flex-direction: column;
            align-items: flex-start;
            div.value {
                margin-top: 15px;
                margin-left: 50px;
            }
        }

    }
}

.popup-box {
    h3 {
        color: #3D4251;
        font-size: $font-size-40;
        //padding-bottom: 20px;
    }
    p {
        color: #3D4251;
        font-size: $font-size-20;
        font-weight: 400;
    }

    div.mailchimp-popup {


        input.form-control {
            height: 90px;
            color: $color-black;
            font-size: 16px;
            background-color:$color-white;
            border-radius: 5px;
            width: 30vw;
            max-width: 500px;
        }

        .btn-send {
            background-color: $color-black;
            padding: 9px;
            color: $color-white;
            border-width: 0;
            width: 250px;
            cursor: pointer;
            height: 100%;
            text-transform: uppercase;
            font-size: 20px;
            &:hover {
                background-color: $color-primary;
            }
        }

        label {
          font-size: 11px;
          font-weight: 400;
          padding-top: 10px;
        }
        @media screen and (max-width: $size-md-max){
          input.form-control {
              height: 50px;
          }
          .btn-send {
              width: 200px;
              font-size: 22px;
          }

        }
        @media screen and (max-width: $size-xs-max){
          .input-wrapper {
              flex-direction: column;
          }
          input.form-control {
              width: 100%;
              margin-bottom: 30px;
          }
        }
    }
}
