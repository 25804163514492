/*!
 * Bootstrap v3.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff; }

input,
button,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #337ab7;
  text-decoration: none; }
  a:hover, a:focus {
    color: #23527c;
    text-decoration: underline; }
  a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

img {
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

[role="button"] {
  cursor: pointer; }

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

h4, .h4,
h5, .h5 {
  margin-top: 10px;
  margin-bottom: 10px; }

h1, .h1 {
  font-size: 36px; }

h2, .h2 {
  font-size: 30px; }

h3, .h3 {
  font-size: 24px; }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 14px; }

p {
  margin: 0 0 10px; }

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; }
  @media (min-width: 768px) {
    .lead {
      font-size: 21px; } }

.text-center {
  text-align: center; }

ul {
  margin-top: 0;
  margin-bottom: 10px; }
  ul ul {
    margin-bottom: 0; }


dd {
  line-height: 1.42857; }

dd {
  margin-left: 0; }

code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .container:before, .container:after {
    display: table;
    content: " "; }
  .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .container-fluid:before, .container-fluid:after {
    display: table;
    content: " "; }
  .container-fluid:after {
    clear: both; }

.row {
  margin-right: -15px;
  margin-left: -15px; }
  .row:before, .row:after {
    display: table;
    content: " "; }
  .row:after {
    clear: both; }

 .col-md-4, .col-sm-6, .col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

 .col-xs-12 {
  float: left; }

.col-xs-12 {
  width: 100%; }

@media (min-width: 768px) {
   .col-sm-6 {
    float: left; }
  .col-sm-6 {
    width: 50%; } }

@media (min-width: 992px) {
   .col-md-4 {
    float: left; }
  .col-md-4 {
    width: 33.33333%; } }

table {
  background-color: transparent; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }
  .table .table {
    background-color: #fff; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }
  input[type="radio"][disabled],
  input[type="checkbox"][disabled] {
    cursor: not-allowed; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control[disabled], .form-control[readonly] {
    background-color: #eeeeee;
    opacity: 1; }
  .form-control[disabled] {
    cursor: not-allowed; }

textarea.form-control {
  height: auto; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; } }

.form-group {
  margin-bottom: 15px; }

.has-error .help-block,
.has-error .control-label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .btn:hover, .btn:focus, .btn.focus {
    color: #333;
    text-decoration: none; }
  .btn:active, .btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
   .btn[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c; }
  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .btn-default:active, .btn-default.active {
    color: #333;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad; }
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c; }
   .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus {
    background-color: #fff;
    border-color: #ccc; }

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.hidden {
  display: none !important; }

@-ms-viewport {
  width: device-width; }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }
.alertify,
.alertify-show,
.alertify-log {
  -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* easeOutBack */ }

.alertify-hide {
  -webkit-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* easeInBack */ }

.alertify-log-hide {
  -webkit-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* easeInBack */ }

.alertify-cover {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  filter: alpha(opacity=0);
  opacity: 0; }

.alertify-cover-hidden {
  display: none; }

.alertify {
  position: fixed;
  z-index: 99999;
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px;
  opacity: 1; }

.alertify-hidden {
  -webkit-transform: translate(0, -150px);
  -moz-transform: translate(0, -150px);
  -ms-transform: translate(0, -150px);
  -o-transform: translate(0, -150px);
  transform: translate(0, -150px);
  opacity: 0;
  display: none; }

/* overwrite display: none; for everything except IE6-8 */
:root * > .alertify-hidden {
  display: block;
  visibility: hidden; }

.alertify-logs {
  position: fixed;
  z-index: 5000;
  bottom: 10px;
  right: 10px;
  width: 300px; }

.alertify-logs-hidden {
  display: none; }

.alertify-log {
  display: block;
  margin-top: 10px;
  position: relative;
  right: -300px;
  opacity: 0; }

.alertify-log-show {
  right: 0;
  opacity: 1; }

.alertify-log-hide {
  -webkit-transform: translate(300px, 0);
  -moz-transform: translate(300px, 0);
  -ms-transform: translate(300px, 0);
  -o-transform: translate(300px, 0);
  transform: translate(300px, 0);
  opacity: 0; }

.alertify-dialog {
  padding: 25px; }

.alertify-resetFocus {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.alertify-inner {
  text-align: center; }

.alertify-text {
  margin-bottom: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 100%; }

.alertify-button,
.alertify-button:hover,
.alertify-button:active,
.alertify-button:visited {
  background: none;
  text-decoration: none;
  border: none;
  /* line-height and font-size for input button */
  line-height: 1.5;
  font-size: 100%;
  display: inline-block;
  cursor: pointer;
  margin-left: 5px; }

@media only screen and (max-width: 680px) {
  .alertify,
  .alertify-logs {
    width: 90%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .alertify {
    left: 5%;
    margin: 0; } }

/**
 * Twitter Bootstrap Look and Feel
 * Based on http://twitter.github.com/bootstrap/
 */
.alertify,
.alertify-log {
  font-family: sans-serif; }

.alertify {
  background: #FFF;
  border: 1px solid #8E8E8E;
  /* browsers that don't support rgba */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding;
  /* Safari 4? Chrome 6? */
  -moz-background-clip: padding;
  /* Firefox 3.6 */
  background-clip: padding-box;
  /* Firefox 4, Safari 5, Opera 10, IE 9 */ }

.alertify-dialog {
  padding: 0; }

.alertify-inner {
  text-align: left; }

.alertify-message {
  padding: 15px;
  margin: 0; }

.alertify-text-wrapper {
  padding: 0 15px; }

.alertify-text {
  color: #555;
  border-radius: 4px;
  padding: 8px;
  background-color: #FFF;
  border: 1px solid #CCC;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.alertify-text:focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6); }

.alertify-buttons {
  padding: 14px 15px 15px;
  background: #F5F5F5;
  border-top: 1px solid #DDD;
  border-radius: 0 0 6px 6px;
  box-shadow: inset 0 1px 0 #FFF;
  text-align: right; }

.alertify-button,
.alertify-button:hover,
.alertify-button:focus,
.alertify-button:active {
  margin-left: 10px;
  border-radius: 4px;
  font-weight: normal;
  padding: 4px 12px;
  text-decoration: none;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  background-image: linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0)); }

.alertify-button:focus {
  outline: none;
  box-shadow: 0 0 5px #2B72D5; }

.alertify-button:active {
  position: relative;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }

.alertify-button-cancel,
.alertify-button-cancel:hover,
.alertify-button-cancel:focus,
.alertify-button-cancel:active {
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.75);
  background-color: #E6E6E6;
  border: 1px solid #BBB;
  color: #333;
  background-image: -webkit-linear-gradient(top, #FFF, #E6E6E6);
  background-image: -moz-linear-gradient(top, #FFF, #E6E6E6);
  background-image: -ms-linear-gradient(top, #FFF, #E6E6E6);
  background-image: -o-linear-gradient(top, #FFF, #E6E6E6);
  background-image: linear-gradient(top, #FFF, #E6E6E6); }

.alertify-button-cancel:hover,
.alertify-button-cancel:focus,
.alertify-button-cancel:active {
  background: #E6E6E6; }

.alertify-button-ok,
.alertify-button-ok:hover,
.alertify-button-ok:focus,
.alertify-button-ok:active {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #04C;
  border: 1px solid #04C;
  border-color: #04C #04C #002A80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #FFF; }

.alertify-button-ok:hover,
.alertify-button-ok:focus,
.alertify-button-ok:active {
  background: #04C; }

.alertify-log {
  background: #D9EDF7;
  padding: 8px 14px;
  border-radius: 4px;
  color: #3A8ABF;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: 1px solid #BCE8F1; }

.alertify-log-error {
  color: #B94A48;
  background: #F2DEDE;
  border: 1px solid #EED3D7; }

.alertify-log-success {
  color: #468847;
  background: #DFF0D8;
  border: 1px solid #D6E9C6; }

.alertify-logs {
  top: 50px;
  left: 50%;
  width: 550px;
  margin-left: -275px; }

.alertify-log {
  text-align: center;
  padding: 30px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.3); }

.alertify-inner {
  background: rgba(218, 255, 228, 0.27); }
  .alertify-inner .alertify-message {
    color: #05b305;
    text-align: center;
    font-weight: 400; }

.alertify-button-ok {
  background-color: #01a904; }

.alertify-cover {
  background-color: rgba(2, 2, 2, 0.761) !important;
  filter: alpha(opacity=1) !important;
  opacity: 1 !important; }
html {
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  -ms-font-feature-settings: "lnum";
  font-feature-settings: "lnum"; }

* {
  margin: 0;
  padding: 0; }

body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0; }
  body h1.referencement {
    display: none !important; }

h1, h2, h3, h4, h5 {
  margin: 0; }

/* to fix a chrome bug */

img {
  max-width: 100%; }

.body {
  overflow-x: hidden; }

html {
  font-size: 20px; }

@media screen and (max-width: 1299px) {
  html {
    font-size: 18px; } }

@media screen and (max-width: 990px) {
  html {
    font-size: 16px; } }

@media screen and (max-width: 767px) {
  html {
    font-size: 14px; } }

/* @include transform(normal_css_value_here) */
/* @include transition(normal_css_value_here) */
/* @include box-shadow(normal_css_value_here) */
/* @include text-shadow(normal_css_value_here) */
/* @include no-select() */
/* @include text-style() */
/***************************************************/
/* Select mixins
/* usage example:
li {
  @include first(4)
  {
    background: red;
  }
}
*/
/* @include to select child elements */
/* Selects the last element */
/* Selects the even elements */
/* Selects the odd elements */
/* Selects the n first elements */
/***************************************************/
/* montserrat-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat/montserrat-v25-latin-300.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-300.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-300.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-300.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-300.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat/montserrat-v25-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-regular.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-regular.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-regular.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat/montserrat-v25-latin-500.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-500.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-500.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-500.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-500.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat/montserrat-v25-latin-600.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-600.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-600.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-600.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-600.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat/montserrat-v25-latin-700.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-700.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-700.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-700.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-700.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat/montserrat-v25-latin-800.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-800.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-800.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-800.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-800.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* montserrat-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/montserrat/montserrat-v25-latin-900.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat/montserrat-v25-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/montserrat-v25-latin-900.woff2") format("woff2"), url("../fonts/montserrat/montserrat-v25-latin-900.woff") format("woff"), url("../fonts/montserrat/montserrat-v25-latin-900.ttf") format("truetype"), url("../fonts/montserrat/montserrat-v25-latin-900.svg#Montserrat") format("svg");
  /* Legacy iOS */ }

/* libre-baskerville-regular - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Libre Baskerville"), local("LibreBaskerville-Regular"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.woff2") format("woff2"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.woff") format("woff"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.ttf") format("truetype"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.svg#LibreBaskerville") format("svg");
  /* Legacy iOS */ }

/* libre-baskerville-700 - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/libre-baskerville/libre-baskerville-v5-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Libre Baskerville Bold"), local("LibreBaskerville-Bold"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-700.woff2") format("woff2"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-700.woff") format("woff"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-700.ttf") format("truetype"), url("../fonts/libre-baskerville/libre-baskerville-v5-latin-700.svg#LibreBaskerville") format("svg");
  /* Legacy iOS */ }

 p, nav.module-menu #menu-mobile ul.menu li > a, nav.module-menu #menu-mobile ul.menu li > .menu-title, h3, li, .slideshow-wrap .title, section.section-02-realisations div.gallery-wrapper .item div.text .title, section.section-02-realisations div.gallery-wrapper-mobile .item div.text .title, footer .footer-wrapper, body, body a, h1, h2, h4, h5, .text p, .text div, .bursts .bursts-container .burst h4 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400; }

 p, nav.module-menu #menu-mobile ul.menu li > a, nav.module-menu #menu-mobile ul.menu li > .menu-title, h3, li, .slideshow-wrap .title, section.section-02-realisations div.gallery-wrapper .item div.text .title, section.section-02-realisations div.gallery-wrapper-mobile .item div.text .title {
  font-weight: 700; }

 nav.module-menu .menu-navbar .wrapper.left .logo a, .bursts .bursts-container .burst h3 {
  font-family: "Libre Baskerville", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
  font-weight: 400; }

 p {
  font-size: 1rem; }

 nav.module-menu #menu-mobile ul.menu li > a, nav.module-menu #menu-mobile ul.menu li > .menu-title {
  font-size: 2rem;
  line-height: 1;
  text-transform: lowercase; }

 nav.module-menu .menu-navbar .wrapper.left .logo a {
  font-size: 1.4rem; }

 footer .footer-wrapper {
  font-size: 0.7rem; }

.flush {
  padding: 0; }

.w100 {
  width: 100%; }

.m8 {
  margin: 2rem; }

.mt8 {
  margin-top: 2rem; }

.mt12 {
  margin-top: 4rem; }

.mr2 {
  margin-right: 0.5rem; }

.mb4 {
  margin-bottom: 1rem; }

.mb8 {
  margin-bottom: 2rem; }

.my8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mx2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mcr4 {
  margin-right: calc(100vw / 12); }

.mcr8 {
  margin-right: calc(100vw * 2 / 12); }

.mcl4 {
  margin-left: calc(100vw / 12); }

.mcl8 {
  margin-left: calc(100vw * 2 / 12); }

.p4 {
  padding: 1rem; }

.pt8 {
  padding-top: 2rem; }

.pt12 {
  padding-top: 4rem; }

.pr2 {
  padding-right: 0.5rem; }

.pb4 {
  padding-bottom: 1rem; }

.pb8 {
  padding-bottom: 2rem; }

.pb12 {
  padding-bottom: 4rem; }

.py2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.py4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.py6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.py8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.py10 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.px2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.px4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px6, nav.module-menu .menu-navbar .wrapper.left .logo, .module-header--caption {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.flex, nav.module-menu .menu-navbar, nav.module-menu .menu-navbar .wrapper.left .logo, footer, footer .footer-wrapper, footer .footer-wrapper .mediaweb a, .bursts .bursts-container, .bursts .bursts-container .burst .text-container .border {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.flex-column, .bursts .bursts-container .burst .text-container .border {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-wrap, footer, footer .footer-wrapper {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.items-start, footer .footer-wrapper {
  -webkit-align-items: flex-start;
  align-items: flex-start; }

.items-end {
  -webkit-align-items: flex-end;
  align-items: flex-end; }

.items-center, nav.module-menu .menu-navbar, nav.module-menu .menu-navbar .wrapper.left .logo, footer, footer .footer-wrapper .mediaweb a, .bursts .bursts-container .burst .text-container .border {
  -webkit-align-items: center;
  align-items: center; }

.items-stretch {
  -webkit-align-items: stretch;
  align-items: stretch; }

.justify-end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end; }

.justify-center, nav.module-menu .menu-navbar .wrapper.left .logo, .bursts .bursts-container .burst .text-container .border {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center; }

.justify-between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between; }

 footer .footer-wrapper {
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around; }

/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.hidden-xs {
  display: block; }

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none; } }

/* Animations */
/* Pour utiliser:
animation: drop-down;
animation-delay: 1s;
*/
@keyframes appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes drop-down {
  from {
    top: -100vh; }
  to {
    top: 0px; } }

@keyframes come-up {
  from {
    bottom: -100vh; }
  to {
    bottom: 0px; } }

@keyframes zoom-in {
  from {
    transform: translate(-100vw); }
  to {
    transform: translate(0); } }

 p, body, .text p, .text div {
  color: #262523; }

 h3 {
  color: #324769; }

 nav.module-menu #menu-mobile ul.menu li > a, nav.module-menu #menu-mobile ul.menu li > .menu-title,
nav.module-menu .menu-navbar .wrapper.left .logo a {
  color: #324769; }

 nav.module-menu .menu-navbar .wrapper.left .logo a:hover, nav.module-menu #menu-mobile ul.menu li:hover > a div {
  color: #fff; }

 body a:hover, body a:focus, footer .footer-wrapper .mediaweb a:hover {
  color: #fff; }

 footer .footer-wrapper, footer .footer-wrapper .mediaweb a {
  color: #b5b5b5; }

 body a {
  color: #E9592D; }

 footer {
  background-color: #262523; }

/** to add hover effect on icon **/

.icon a {
  background-color: inherit;
  position: relative; }
  .icon a .overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    background-color: #324769;
    mix-blend-mode: multiply;
    transition: all 0.2s ease-in; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .icon a .overlay {
        background: rgba(0, 0, 0, 0.5); } }
    @supports (-ms-ime-align: auto) {
      .icon a .overlay {
        background: rgba(0, 0, 0, 0.5); } }
  .icon a:hover .overlay {
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-out; }

/*
    Where we set the typography of the template
 */
body {
  line-height: 1; }
  body a {
    text-decoration: none !important; }
    body a:hover, body a:focus {
      text-decoration: none !important; }

h1, h2, h3, h4, h5 {
  line-height: 1.5; }

h3 {
  text-transform: uppercase;
  font-size: 1.2rem; }

h4 {
  font-size: 1rem; }

p {
  line-height: 1.5;
  font-weight: 400;
  font-size: 0.8rem; }

ul {
  padding-left: 20px; }

li {
  line-height: 3;
  text-transform: uppercase; }

.text p, .text div {
  line-height: 1.4; }

.text strong {
  color: #E9592D;
  font-weight: 900; }

.btn {
  background-color: #E9592D;
  color: #fff;
  padding: 15px;
  font-size: 0.9rem;
  width: 100%;
  border-radius: 5px;
  transition: all 0.2s ease-in; }
  .btn:hover {
    transition: all 0.2s ease-in;
    background-color: #fff;
    color: #000; }

/* GENERAL TEMPLATE */
body {
  background-color: #fff; }

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */

/** Adds a shadow to the div element **/
.shadow {
  position: relative; }
  .shadow::after {
    content: '';
    z-index: 0;
    width: 100%;
    top: 100%;
    height: 40px;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
/*****************************************************/
/* needs _sources/js/main.js to toggle var menu = ".menu-mobile"; */
nav.module-menu {
  position: relative;
  /****************************************************
    /* Set if the menu is over the slide show or not
    */
  padding-bottom: 0;
  /************ menu desktop ***************************/
  /************ menu toggle ***************************/
  /************ menu mobile ***************************/ }
  nav.module-menu ul {
    list-style: none; }
  @media screen and (max-width: 990px) {
    nav.module-menu {
      padding-bottom: 0; } }
  nav.module-menu .menu-navbar {
    top: 0;
    position: fixed;
    margin-top: 90px;
    z-index: 500;
    width: 100%;
    height: 65px;
    transition: all 0.3s ease; }
    nav.module-menu .menu-navbar.scrolled {
      background-color: #F9F9F9;
      margin-top: 0;
      top: 0;
      transition: all 0.3s ease; }
      nav.module-menu .menu-navbar.scrolled .wrapper .menu-toggle .word {
        color: #000 !important;
        transition: .3s; }
      nav.module-menu .menu-navbar.scrolled .wrapper.left .logo img:nth-child(1) {
        visibility: hidden;
        opacity: 0;
        transition: .3s; }
      nav.module-menu .menu-navbar.scrolled .wrapper.left .logo img:nth-child(2) {
        visibility: visible;
        transition: .3s;
        opacity: 1; }
    nav.module-menu .menu-navbar.nav-visible {
      top: 0;
      transition: all 0.3s ease; }
    @media screen and (max-width: 991px) {
      nav.module-menu .menu-navbar {
        height: 75px;
        transition: all 0.3s ease;
        background-color: #F9F9F9;
        margin-top: 0;
        top: 0;
        justify-content: space-between;
        transition: all 0.3s ease; }
        nav.module-menu .menu-navbar.nav-visible {
          top: 0;
          transition: all 0.3s ease; }
        nav.module-menu .menu-navbar .wrapper .menu-toggle .word {
          color: #000 !important;
          transition: .3s;
          margin-right: 35px;
          font-size: 25px; }
        nav.module-menu .menu-navbar .wrapper.left .logo img:nth-child(1) {
          visibility: hidden;
          opacity: 0;
          transition: .3s; }
        nav.module-menu .menu-navbar .wrapper.left .logo img:nth-child(2) {
          visibility: visible !important;
          transition: .3s;
          opacity: 1 !important; } }
    nav.module-menu .menu-navbar .wrapper.left {
      flex: 0 0 auto;
      margin-left: 5%; }
      nav.module-menu .menu-navbar .wrapper.left .logo {
        height: 100%;
        width: auto; }
        nav.module-menu .menu-navbar .wrapper.left .logo img {
          min-width: 98px; }
        nav.module-menu .menu-navbar .wrapper.left .logo a {
          position: relative; }
          nav.module-menu .menu-navbar .wrapper.left .logo a img.overlay {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 13px;
            left: 0; }
        @media screen and (max-width: 991px) {
          nav.module-menu .menu-navbar .wrapper.left .logo {
            max-width: 220px; } }
  nav.module-menu .menu-toggle {
    cursor: pointer; }
    nav.module-menu .menu-toggle .word {
      font-weight: 700;
      color: #fff;
      font-size: 30px;
      transition: .3s;
      text-transform: lowercase; }
      nav.module-menu .menu-toggle .word:hover {
        color: #E9592D;
        transition: .3s; }
  nav.module-menu #menu-mobile {
    top: 0;
    width: 70vw;
    right: -70vw;
    z-index: 20000;
    overflow: hidden;
    position: fixed;
    background-color: #E9592D;
    transition: all 0.3s ease-in;
    flex-direction: column; }
    nav.module-menu #menu-mobile.toggled {
      overflow-y: auto;
      transform: translate(-100%, 0%); }
    nav.module-menu #menu-mobile .menu-mobile-wrapper {
      display: flex;
      flex-direction: column;
      margin: 110px 10% 5% 10%;
      padding-bottom: 30px; }
      nav.module-menu #menu-mobile .menu-mobile-wrapper .menu-toggle-wrapper {
        border-bottom: 2px solid #F5F5F5;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 15px; }
        nav.module-menu #menu-mobile .menu-mobile-wrapper .menu-toggle-wrapper .menu-toggle .word {
          font-weight: 700;
          color: #fff;
          font-size: 1.6rem;
          transition: .3s;
          text-transform: lowercase; }
          nav.module-menu #menu-mobile .menu-mobile-wrapper .menu-toggle-wrapper .menu-toggle .word:hover {
            color: #324769;
            transition: .3s; }
    nav.module-menu #menu-mobile ul.menu {
      margin: 25px auto;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start; }
      nav.module-menu #menu-mobile ul.menu li {
        text-align: center;
        line-height: 100%;
        padding: 12px 0;
        width: auto;
        width: 50%; }
        nav.module-menu #menu-mobile ul.menu li:nth-child(1), nav.module-menu #menu-mobile ul.menu li:nth-child(2), nav.module-menu #menu-mobile ul.menu li:nth-child(3) {
          width: 100%; }
        nav.module-menu #menu-mobile ul.menu li > a, nav.module-menu #menu-mobile ul.menu li > .menu-title {
          font-weight: 600;
          text-transform: lowercase;
          position: relative;
          transition: .3s; }
          nav.module-menu #menu-mobile ul.menu li > a > a div, nav.module-menu #menu-mobile ul.menu li > .menu-title > a div {
            transition: .3s; }
        nav.module-menu #menu-mobile ul.menu li > a div::after {
          content: '';
          position: absolute;
          width: 49px;
          height: 16px;
          background: url(../images/arrow_back.png);
          right: 0;
          background-size: contain !important;
          background-repeat: no-repeat;
          top: 50%;
          transform: translate(0, -50%);
          opacity: 0;
          transition: 0.3s; }
        nav.module-menu #menu-mobile ul.menu li:hover {
          transition: .3s; }
          nav.module-menu #menu-mobile ul.menu li:hover > a div {
            transition: .3s; }
            nav.module-menu #menu-mobile ul.menu li:hover > a div::after {
              content: '';
              width: 49px;
              height: 16px;
              background-size: contain !important;
              background-repeat: no-repeat !important;
              background: url(../images/arrow_back.png);
              right: -50px;
              top: 50%;
              transform: translate(10px, -50%);
              opacity: 1;
              transition: 0.3s; }
        nav.module-menu #menu-mobile ul.menu li.active > a, nav.module-menu #menu-mobile ul.menu li.active > a div, nav.module-menu #menu-mobile ul.menu li.phone > a, nav.module-menu #menu-mobile ul.menu li.phone > a div, nav.module-menu #menu-mobile ul.menu li.lang > a, nav.module-menu #menu-mobile ul.menu li.lang > a div {
          color: #fff; }
    @media screen and (max-width: 1299px) {
      nav.module-menu #menu-mobile ul.menu li {
        width: 100%; } }
    @media screen and (max-width: 991px) {
      nav.module-menu #menu-mobile .menu-mobile-wrapper {
        display: flex;
        flex-direction: column;
        margin: 35px 5% 5% 5%;
        padding-bottom: 20px; } }
    @media screen and (max-width: 480px) {
      nav.module-menu #menu-mobile {
        width: 100%;
        right: -100%; } }

/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
footer {
  min-height: 100px;
  background-color: #000; }
  footer .footer-wrapper {
    width: 100%;
    margin: 0 auto; }
    footer .footer-wrapper .copyright {
      font-size: 13px;
      margin-bottom: 10px; }
    footer .footer-wrapper .mediaweb {
      margin-bottom: 10px;
      margin: auto; }
      footer .footer-wrapper .mediaweb a img {
        margin: auto; }
      footer .footer-wrapper .mediaweb a span {
        padding-left: 5px; }
    footer .footer-wrapper ul.menu-footer {
      max-width: 600px;
      padding: 0 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start; }
      footer .footer-wrapper ul.menu-footer li {
        width: 150px;
        font-size: 16px;
        line-height: 1;
        padding-bottom: 20px; }
        footer .footer-wrapper ul.menu-footer li a div {
          color: #CBD0D3;
          white-space: nowrap;
          text-transform: initial;
          font-weight: 600; }
        footer .footer-wrapper ul.menu-footer li:hover a div {
          color: #E9592D; }
      @media screen and (max-width: 767px) {
        footer .footer-wrapper ul.menu-footer {
          justify-content: center; }
          footer .footer-wrapper ul.menu-footer li {
            justify-content: center; } }
    footer .footer-wrapper div.mailchimp-form form {
      max-height: 70px; }
    footer .footer-wrapper div.mailchimp-form input.form-control {
      height: 34px;
      color: #CBD0D3;
      font-size: 16px;
      background-color: #000;
      border: none;
      border-radius: 0; }
    footer .footer-wrapper div.mailchimp-form .btn-send {
      background-color: #000;
      padding: 9px;
      border-width: 0;
      cursor: pointer; }
      footer .footer-wrapper div.mailchimp-form .btn-send:hover {
        background-color: #E9592D; }
    footer .footer-wrapper div.mailchimp-form .input-wrapper {
      border: 1px solid #CBD0D3;
      height: 36px; }
    footer .footer-wrapper div.mailchimp-form label {
      font-size: 11px;
      font-weight: 400;
      padding-top: 10px; }
    @media screen and (max-width: 767px) {
      footer .footer-wrapper {
        width: 100%; } }

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
.bursts {
  width: 100%; }
  .bursts .bursts-container .burst {
    position: relative; }
    .bursts .bursts-container .burst .text-container, .bursts .bursts-container .burst .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .bursts .bursts-container .burst .text-container {
      padding: 1.25rem; }
      .bursts .bursts-container .burst .text-container .border {
        border: 1px solid #fff;
        width: 100%;
        height: 100%;
        transition: all 0.2s ease-out; }
    .bursts .bursts-container .burst .overlay {
      opacity: 0;
      transition: all 0.7s ease-out; }
    .bursts .bursts-container .burst h3, .bursts .bursts-container .burst h4 {
      color: #fff;
      transition: all 0.2s ease-out; }
    .bursts .bursts-container .burst h3 {
      font-size: 4vw;
      font-weight: 600;
      text-transform: none; }
    .bursts .bursts-container .burst h4 {
      font-size: 2.5vw; }
  .bursts .bursts-container a:hover .burst .overlay {
    opacity: 1;
    transition: all 0.3s ease; }
  .bursts .bursts-container a:hover .burst h3, .bursts .bursts-container a:hover .burst h4 {
    color: #324769;
    transition: all 0.3s ease; }
  .bursts .bursts-container a:hover .burst .border {
    transition: all 0.3s ease; }

@media screen and (max-width: 480px) {
  .bursts .bursts-container {
    flex-direction: column; }
    .bursts .bursts-container .burst h3 {
      font-size: 2rem; }
    .bursts .bursts-container .burst h4 {
      font-size: 1rem; } }

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
.slideshow-wrap {
  position: relative;
  background-color: black; }
  .slideshow-wrap .subtitle {
    color: #000;
    font-size: 0.9rem;
    text-align: center;
    display: block; }
    .slideshow-wrap .subtitle.marbre {
      color: #fff; }
  .slideshow-wrap .title {
    padding-bottom: 0.5rem;
    color: #000;
    font-size: 2vw;
    text-align: center;
    display: block; }
    .slideshow-wrap .title.marbre {
      color: #fff; }
  .slideshow-wrap .btn-slider {
    max-width: 250px;
    min-width: 200px;
    width: 16vw;
    margin: auto;
    margin-top: 25px;
    display: block;
    background-color: rgba(255, 255, 255, 0); }
    .slideshow-wrap .btn-slider.marbre {
      border: 1px solid #eeb412;
      color: #fff; }
    .slideshow-wrap .btn-slider.evaluateur, .slideshow-wrap .btn-slider.garage {
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid #000;
      color: #000; }
    .slideshow-wrap .btn-slider:hover {
      background-color: #000;
      border: 1px solid #000;
      color: #fff; }
  .slideshow-wrap .ls-l a {
    width: auto; }
  @media screen and (max-width: 767px) {
    .slideshow-wrap .ls-l a {
      font-size: 0.5rem;
      border-width: 1px;
      padding: 0.25rem; }
    .slideshow-wrap .subtitle {
      font-size: 16px; }
    .slideshow-wrap .title {
      font-size: 22px; }
    .slideshow-wrap .btn-slider {
      font-size: 18px; } }

.module-header {
  position: relative; }
  .module-header--image {
    width: 100%; }
  .module-header--caption {
    position: absolute;
    bottom: 50px;
    left: 5%;
    text-align: center; }
    .module-header--caption .icons {
      margin-right: 20px; }

.module-formulaire form input.form-control, .module-formulaire form textarea.form-control {
  height: 100px;
  color: #333333;
  font-size: 0.9rem;
  background-color: #f5f5f5;
  border: none;
  border-radius: 2px;
  outline: none;
  border: none;
  box-shadow: inset 1px 1px 2px #8a8484, inset -4px -4px 2px #fff; }
  @media screen and (max-width: 767px) {
    .module-formulaire form input.form-control, .module-formulaire form textarea.form-control {
      height: 60px; } }

.module-formulaire form input::placeholder, .module-formulaire form textarea::placeholder {
  font-size: 0.9rem;
  color: #000 !important; }

.module-formulaire form label {
  color: #777777;
  font-size: 0.9rem; }

.module-formulaire form .title {
  text-transform: uppercase;
  color: #333333;
  font-weight: bold;
  margin-top: 1em; }

.module-formulaire form .center {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.module-formulaire form textarea {
  height: 200px !important; }

.module-formulaire form .buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0; }

.module-formulaire .has-error .form-control {
  color: #a94442; }

.module-formulaire .help-block {
  min-height: 5px;
  margin-bottom: 0; }

.module-formulaire .with-errors li {
  line-height: 1; }

.module-formulaire .form-group {
  margin-bottom: 0; }

.module-formulaire .btn.btn-contact {
  background-color: #f5f5f5;
  color: #000;
  font-weight: 500;
  box-shadow: -2px -2px 2px #fff, 2px 2px 2px #8a8484; }
  .module-formulaire .btn.btn-contact:hover {
    color: #fff;
    background-color: #000; }

.module-formulaire .messages {
  padding: 30px;
  margin-bottom: 2em; }
  .module-formulaire .messages.errors p {
    font-size: 1rem;
    line-height: 1;
    margin: 0; }
  .module-formulaire .messages.errors {
    background-color: rgba(255, 26, 26, 0.7);
    border-radius: 5px;
    border: 5px solid #a94442;
    color: #a94442; }
    .module-formulaire .messages.errors p {
      color: #a94442; }

/*******************************************************************************
* COMMON TO ALL PAGES
*/
/*****************************************************/
/* INDEX.PHP
/*****************************************************/
.section_video__wrapper {
  position: relative; }
  .section_video__wrapper video {
    width: 100%;
    height: auto; }
  .section_video__wrapper_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); }
  .section_video__wrapper_caption {
    position: absolute;
    bottom: 60px;
    left: 15%;
    right: 5%;
    display: flex; }
    .section_video__wrapper_caption h3 {
      color: #fff;
      font-size: 3rem;
      font-weight: 600;
      line-height: 1;
      text-transform: inherit; }
    .section_video__wrapper_caption h4, .section_video__wrapper_caption p {
      color: #fff;
      font-weight: 600; }
    .section_video__wrapper_caption h4 {
      font-size: 0.9rem;
      margin-bottom: 15px; }
    .section_video__wrapper_caption .btn-wrapper {
      max-width: 260px; }
      .section_video__wrapper_caption .btn-wrapper a.btn {
        border: 1px solid #E9592D; }
        .section_video__wrapper_caption .btn-wrapper a.btn:hover {
          transition: all 0.2s ease-in;
          background-color: rgba(255, 255, 255, 0);
          color: #fff; }
    .section_video__wrapper_caption div.main {
      flex: 0 0 auto;
      margin-right: auto; }
    .section_video__wrapper_caption div.textes {
      margin-right: 2rem;
      width: 275px; }

@media screen and (max-width: 767px) {
  .section_video__wrapper_caption div.textes {
    display: none; }
  .section_video__wrapper_caption h3 {
    font-size: 2rem; } }

section.section-01-mobile {
  background-color: #324769;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  section.section-01-mobile .text-wrapper {
    padding: 0 15px; }
  section.section-01-mobile h4, section.section-01-mobile p {
    color: #fff; }
  section.section-01-mobile h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px; }
  section.section-01-mobile p {
    font-size: 16px; }
  section.section-01-mobile img {
    border-top-left-radius: 80px;
    border-top-right-radius: 80px; }
  @media screen and (min-width: 767px) {
    section.section-01-mobile {
      display: none; } }

section.section-01-accueil {
  margin-top: 40px;
  padding: 100px 0;
  background: linear-gradient(to right, #E9592D 0%, #E9592D 80%, #fff 80%, #fff 100%); }
  section.section-01-accueil div.left-side {
    width: 45%;
    padding-left: 5%;
    padding-right: 2rem; }
  section.section-01-accueil div.right-side {
    width: 55%; }
    section.section-01-accueil div.right-side img {
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px; }
  section.section-01-accueil h3 {
    font-size: 4vw;
    color: #000;
    text-transform: inherit; }
  section.section-01-accueil h4 {
    color: #000;
    font-size: 1.3rem;
    font-weight: 600; }
  section.section-01-accueil p {
    font-size: 1rem;
    color: #000; }
  section.section-01-accueil .btn-wrapper {
    max-width: 270px; }
  section.section-01-accueil .btn-black {
    background-color: #000;
    border: 1px solid #000; }
    section.section-01-accueil .btn-black:hover {
      background-color: rgba(255, 255, 255, 0);
      font-weight: 500; }
  @media screen and (max-width: 767px) {
    section.section-01-accueil {
      margin-top: 0;
      padding: 60px 0;
      background: linear-gradient(to right, #E9592D 0%, #E9592D 80%, #E9592D 80%, #E9592D 100%); }
      section.section-01-accueil div.left-side {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%; }
      section.section-01-accueil div.right-side {
        display: none; }
      section.section-01-accueil h3 {
        font-size: 30px; }
      section.section-01-accueil h4 {
        font-size: 22px; }
      section.section-01-accueil p {
        font-size: 18px;
        font-weight: 500; } }

section.section-etapes {
  padding: 50px 10%; }
  section.section-etapes h3, section.section-etapes p {
    text-align: center;
    font-weight: 700;
    color: #000; }
  section.section-etapes h3 {
    font-size: 1.5rem; }
  section.section-etapes p {
    font-size: 18px;
    position: relative;
    margin-bottom: 0; }
    section.section-etapes p::before {
      position: absolute;
      content: '1';
      font-size: 30px;
      color: #04050445;
      left: -30px;
      top: -8px; }
    section.section-etapes p.etape2::before {
      content: '2'; }
    section.section-etapes p.etape3::before {
      content: '3'; }
    section.section-etapes p.etape4::before {
      content: '4'; }
    section.section-etapes p.etape5::before {
      content: '5'; }
  @media screen and (max-width: 1299px) {
    section.section-etapes .icons-wrapper {
      justify-content: center; } }

section.section-reviews {
  padding: 100px 0; }

/*******************************************************************************
* PAGE X
*/
div.container-fluid.row {
  margin: 0; }

section.section-01-histoire {
  background-color: #F5F5F5;
  padding: 60px 0; }
  section.section-01-histoire div.container-fluid.row {
    box-shadow: -3px -3px 6px #FFFFFF, 3px 3px 6px #d4d0d0; }
  section.section-01-histoire div.text-bloc {
    padding: 70px 10%; }
  section.section-01-histoire h3 {
    color: #363636;
    text-align: center;
    font-size: 1.5rem; }
  section.section-01-histoire p {
    font-size: 0.9rem;
    color: #040504;
    max-width: 1500px; }
  section.section-01-histoire .btn-wrapper {
    max-width: 500px;
    margin: auto; }
    section.section-01-histoire .btn-wrapper .btn-black {
      margin-top: 50px;
      background: #000;
      color: #fff;
      text-transform: uppercase;
      position: relative; }
      section.section-01-histoire .btn-wrapper .btn-black div.first {
        transition: .3s;
        opacity: 1; }
      section.section-01-histoire .btn-wrapper .btn-black div.overlay {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
      section.section-01-histoire .btn-wrapper .btn-black:hover {
        color: #000;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #000; }
        section.section-01-histoire .btn-wrapper .btn-black:hover div.first {
          opacity: 0; }
        section.section-01-histoire .btn-wrapper .btn-black:hover div.overlay {
          opacity: 1; }

section.section-01-services, section.section-01-processus, section.section-01-realisations {
  padding: 60px 10%; }
  section.section-01-services div.text-bloc, section.section-01-processus div.text-bloc, section.section-01-realisations div.text-bloc {
    padding: 70px 0; }
  section.section-01-services h3, section.section-01-processus h3, section.section-01-realisations h3 {
    color: #363636;
    text-align: center;
    font-size: 1.5rem; }
  section.section-01-services p, section.section-01-processus p, section.section-01-realisations p {
    font-size: 0.9rem;
    color: #040504; }
  section.section-01-services .btn-wrapper, section.section-01-processus .btn-wrapper, section.section-01-realisations .btn-wrapper {
    max-width: 300px; }
    section.section-01-services .btn-wrapper .btn-black, section.section-01-processus .btn-wrapper .btn-black, section.section-01-realisations .btn-wrapper .btn-black {
      margin-top: 50px;
      background: #000;
      color: #fff;
      text-transform: uppercase; }
      section.section-01-services .btn-wrapper .btn-black:hover, section.section-01-processus .btn-wrapper .btn-black:hover, section.section-01-realisations .btn-wrapper .btn-black:hover {
        color: #000;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #000; }
  section.section-01-services div.services-bloc, section.section-01-processus div.services-bloc, section.section-01-realisations div.services-bloc {
    display: inline-grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px; }
    section.section-01-services div.services-bloc > div:nth-child(1), section.section-01-processus div.services-bloc > div:nth-child(1), section.section-01-realisations div.services-bloc > div:nth-child(1) {
      background-color: #324769; }
    section.section-01-services div.services-bloc > div:nth-child(2), section.section-01-processus div.services-bloc > div:nth-child(2), section.section-01-realisations div.services-bloc > div:nth-child(2) {
      background-color: #000; }
    section.section-01-services div.services-bloc > div:nth-child(3), section.section-01-processus div.services-bloc > div:nth-child(3), section.section-01-realisations div.services-bloc > div:nth-child(3) {
      background-color: #C06B2D; }
    section.section-01-services div.services-bloc > div:nth-child(4), section.section-01-processus div.services-bloc > div:nth-child(4), section.section-01-realisations div.services-bloc > div:nth-child(4) {
      background-color: #E9592D; }
    section.section-01-services div.services-bloc > div, section.section-01-processus div.services-bloc > div, section.section-01-realisations div.services-bloc > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px;
      height: 40vw;
      height: auto; }
      section.section-01-services div.services-bloc > div h4, section.section-01-processus div.services-bloc > div h4, section.section-01-realisations div.services-bloc > div h4, section.section-01-services div.services-bloc > div p, section.section-01-processus div.services-bloc > div p, section.section-01-realisations div.services-bloc > div p {
        color: #fff;
        text-align: left;
        line-height: 1.5; }
      section.section-01-services div.services-bloc > div h4, section.section-01-processus div.services-bloc > div h4, section.section-01-realisations div.services-bloc > div h4 {
        font-size: 1.2rem; }
      section.section-01-services div.services-bloc > div p, section.section-01-processus div.services-bloc > div p, section.section-01-realisations div.services-bloc > div p {
        font-size: 0.9rem; }
  @media screen and (max-width: 1600px) {
    section.section-01-services div.services-bloc > div p, section.section-01-processus div.services-bloc > div p, section.section-01-realisations div.services-bloc > div p {
      font-size: 0.8rem; } }
  @media screen and (max-width: 1300px) {
    section.section-01-services, section.section-01-processus, section.section-01-realisations {
      padding: 60px 15px; }
      section.section-01-services div.services-bloc > div, section.section-01-processus div.services-bloc > div, section.section-01-realisations div.services-bloc > div {
        height: auto; } }
  @media screen and (max-width: 990px) {
    section.section-01-services div.services-bloc, section.section-01-processus div.services-bloc, section.section-01-realisations div.services-bloc {
      display: inline-grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      grid-gap: 10px; } }
  @media screen and (max-width: 767px) {
    section.section-01-services div.services-bloc, section.section-01-processus div.services-bloc, section.section-01-realisations div.services-bloc {
      grid-template-rows: auto; }
      section.section-01-services div.services-bloc > div, section.section-01-processus div.services-bloc > div, section.section-01-realisations div.services-bloc > div {
        padding: 30px 10px; }
        section.section-01-services div.services-bloc > div p, section.section-01-processus div.services-bloc > div p, section.section-01-realisations div.services-bloc > div p {
          font-size: 16px; } }

section.section-01-processus {
  padding: 60px 10% 0 10%; }
  section.section-01-processus div.text-bloc {
    padding: 0; }
  section.section-01-processus .btn-wrapper {
    margin: auto; }
    section.section-01-processus .btn-wrapper .btn {
      background-color: rgba(255, 255, 255, 0);
      border: 2px solid #000;
      color: #000; }
      section.section-01-processus .btn-wrapper .btn:hover {
        background-color: #000;
        color: #fff; }
  @media screen and (max-width: 767px) {
    section.section-01-processus {
      padding: 30px 5% 0 5%; } }

section.section-01-realisations {
  background-color: #F5F5F5; }
  section.section-01-realisations div.text-bloc {
    padding: 0; }

section.section-02-realisations {
  background-color: #F5F5F5; }
  section.section-02-realisations div.gallery-wrapper .item {
    margin-bottom: 50px;
    position: relative; }
    section.section-02-realisations div.gallery-wrapper .item:last-child {
      margin-bottom: 0; }
    section.section-02-realisations div.gallery-wrapper .item div.text {
      position: absolute;
      right: 0;
      left: 71%;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px; }
      section.section-02-realisations div.gallery-wrapper .item div.text .subtitle {
        color: #000;
        font-size: 0.9rem;
        text-align: center;
        display: block; }
        section.section-02-realisations div.gallery-wrapper .item div.text .subtitle.marbre {
          color: #fff; }
      section.section-02-realisations div.gallery-wrapper .item div.text .title {
        padding-bottom: 0.5rem;
        color: #000;
        font-size: 2vw;
        text-align: center;
        display: block; }
        section.section-02-realisations div.gallery-wrapper .item div.text .title.marbre {
          color: #fff; }
      section.section-02-realisations div.gallery-wrapper .item div.text .btn-slider {
        max-width: 250px;
        min-width: 200px;
        width: 16vw;
        margin: auto;
        margin-top: 25px;
        display: block;
        background-color: rgba(255, 255, 255, 0); }
        section.section-02-realisations div.gallery-wrapper .item div.text .btn-slider.marbre {
          border: 1px solid #eeb412;
          color: #fff; }
        section.section-02-realisations div.gallery-wrapper .item div.text .btn-slider.evaluateur, section.section-02-realisations div.gallery-wrapper .item div.text .btn-slider.garage {
          background-color: rgba(255, 255, 255, 0);
          border: 1px solid #000;
          color: #000; }
        section.section-02-realisations div.gallery-wrapper .item div.text .btn-slider:hover {
          background-color: #000;
          border: 1px solid #000;
          color: #fff; }
  section.section-02-realisations div.gallery-wrapper-mobile .item {
    margin-bottom: 50px;
    position: relative; }
    section.section-02-realisations div.gallery-wrapper-mobile .item div.text {
      position: absolute;
      right: 0;
      left: 0;
      top: 50%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px; }
      section.section-02-realisations div.gallery-wrapper-mobile .item div.text .subtitle {
        color: #000;
        font-size: 16px;
        text-align: center;
        display: block; }
        section.section-02-realisations div.gallery-wrapper-mobile .item div.text .subtitle.marbre {
          color: #fff; }
      section.section-02-realisations div.gallery-wrapper-mobile .item div.text .title {
        padding-bottom: 0.5rem;
        color: #000;
        font-size: 24px;
        text-align: center;
        display: block; }
        section.section-02-realisations div.gallery-wrapper-mobile .item div.text .title.marbre {
          color: #fff; }
      section.section-02-realisations div.gallery-wrapper-mobile .item div.text .btn-slider {
        max-width: 250px;
        min-width: 200px;
        width: 16vw;
        margin: auto;
        margin-top: 25px;
        display: block;
        background-color: rgba(255, 255, 255, 0); }
        section.section-02-realisations div.gallery-wrapper-mobile .item div.text .btn-slider.marbre {
          border: 1px solid #eeb412;
          color: #fff; }
        section.section-02-realisations div.gallery-wrapper-mobile .item div.text .btn-slider.evaluateur, section.section-02-realisations div.gallery-wrapper-mobile .item div.text .btn-slider.garage {
          background-color: rgba(255, 255, 255, 0);
          border: 1px solid #000;
          color: #000; }
        section.section-02-realisations div.gallery-wrapper-mobile .item div.text .btn-slider:hover {
          background-color: #000;
          border: 1px solid #000;
          color: #fff; }

section.realisation-text {
  background-color: #F5F5F5;
  padding-bottom: 50px; }
  section.realisation-text div.container-fluid.row {
    padding: 60px 10%; }
  section.realisation-text h3 {
    color: #040504;
    text-align: left;
    font-size: 1.5rem; }
  section.realisation-text div.icons-wrapper {
    box-shadow: -3px -3px 6px #FFFFFF, 3px 3px 6px #d4d0d0; }
  @media screen and (max-width: 1299px) {
    section.realisation-text div.container-fluid.row {
      flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    section.realisation-text div.container-fluid.row {
      flex-direction: column; } }

section.section-coordonnees {
  background-color: #F5F5F5;
  padding-bottom: 50px; }
  section.section-coordonnees div.container-fluid.row {
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap; }
  section.section-coordonnees div.left-side {
    width: 45%; }
  section.section-coordonnees div.right-side {
    width: 55%; }
  section.section-coordonnees p {
    font-size: 16px; }
  section.section-coordonnees h3 {
    font-size: 1.5rem;
    color: #000;
    padding-bottom: 20px; }
    section.section-coordonnees h3::after {
      width: 60px;
      height: 3px;
      margin-top: 30px;
      content: '';
      display: block;
      background-color: #000; }
  section.section-coordonnees div.info {
    margin-bottom: 50px; }
    section.section-coordonnees div.info .label {
      width: 170px; }
      section.section-coordonnees div.info .label .icon {
        width: 40px; }
      section.section-coordonnees div.info .label h4 {
        font-size: 16px;
        font-weight: 700;
        color: #000; }
    section.section-coordonnees div.info .value a, section.section-coordonnees div.info .value p {
      color: #040504;
      font-weight: 600;
      font-size: 16px; }
  @media screen and (max-width: 1299px) {
    section.section-coordonnees div.left-side, section.section-coordonnees div.right-side {
      width: 100%; } }
  @media screen and (max-width: 767px) {
    section.section-coordonnees div.container-fluid.row {
      padding: 60px 5%; }
      section.section-coordonnees div.container-fluid.row .form-group {
        padding: 0; } }
  @media screen and (max-width: 350px) {
    section.section-coordonnees div.info {
      flex-direction: column;
      align-items: flex-start; }
      section.section-coordonnees div.info div.value {
        margin-top: 15px;
        margin-left: 50px; } }

.popup-box h3 {
  color: #3D4251;
  font-size: 2rem; }

.popup-box p {
  color: #3D4251;
  font-size: 1rem;
  font-weight: 400; }

.popup-box div.mailchimp-popup input.form-control {
  height: 90px;
  color: #000;
  font-size: 16px;
  background-color: #fff;
  border-radius: 5px;
  width: 30vw;
  max-width: 500px; }

.popup-box div.mailchimp-popup .btn-send {
  background-color: #000;
  padding: 9px;
  color: #fff;
  border-width: 0;
  width: 250px;
  cursor: pointer;
  height: 100%;
  text-transform: uppercase;
  font-size: 20px; }
  .popup-box div.mailchimp-popup .btn-send:hover {
    background-color: #E9592D; }

.popup-box div.mailchimp-popup label {
  font-size: 11px;
  font-weight: 400;
  padding-top: 10px; }

@media screen and (max-width: 1299px) {
  .popup-box div.mailchimp-popup input.form-control {
    height: 50px; }
  .popup-box div.mailchimp-popup .btn-send {
    width: 200px;
    font-size: 22px; } }

@media screen and (max-width: 767px) {
  .popup-box div.mailchimp-popup .input-wrapper {
    flex-direction: column; }
  .popup-box div.mailchimp-popup input.form-control {
    width: 100%;
    margin-bottom: 30px; } }
